import React from "react";
import {AppText, BigText} from "../styles/AuthStyles";

export default function PriceData({type}) {
   if (type === "card") {
      return (
         <AppText>
            <b style={{color: "inherit"}}> Costo mensual 10 dlls</b>
         </AppText>
      );
   }
   return (
      {/*
      <React.Fragment>
         <AppText>-------------------</AppText>
         <BigText>México</BigText>
         <AppText>Pago mensual por 180 Pesos</AppText>
         <AppText>Pago trimestral por 540 Pesos</AppText>
         <AppText>Pago semestral por 900 Pesos</AppText>
         <AppText>Pago anual por 1500 Pesos</AppText>
         <React.Fragment>
            <AppText>FAMA STAND UP SAS DE CV</AppText>
            <AppText>BANCO: Banamex</AppText>
            <AppText>CUENTA CLABE: 002180701162687850</AppText>
         </React.Fragment>
         <AppText>-------------------</AppText>
         <BigText>Colombia</BigText>
         <AppText>Pago mensual por 28.800 COP</AppText>
         <AppText>Pago trimestral por 86.400 Pesos</AppText>
         <AppText>Pago semestral por 140.000 COP</AppText>
         <AppText>Pago anual por 250.000 COP</AppText>

         <React.Fragment>
            <AppText>Free Ticket SAS</AppText>
            <AppText>NIT 901.257.240-3</AppText>
            <AppText>BANCO: Davivienda</AppText>
            <AppText>CUENTA CORRIENTE:166169996948</AppText>
         </React.Fragment>
         <AppText>-------------------</AppText>
      </React.Fragment>
      */}
   );
}
