import styled from "styled-components";
import {Link} from "react-router-dom";
import {Button} from "./ButtonStyles";

export const HeadCard = styled.div`
   width: 100%;
   background: ${(props) => props.theme.primary};
   border-radius: 0.5em;
   overflow: auto;
   position: absolute;
   top: 0;
   left: 0;
   padding: 1em;
   padding-bottom: 0;
   width: 50%;
   border-bottom-right-radius: 0;
   border-bottom-left-radius: 0;

   & .actionButton {
      color: ${(props) => props.theme.background};
      font-size: 1.5em;
      cursor: pointer;
      padding: 0 0.5em;

      &.cancelbutton {
         padding-right: 0;
         float: right;
      }

      &.backbutton {
         padding-left: 0;
         float: left;
      }
   }

   @media screen and (max-width: 1050px) {
      // desk
      width: 37%;
   }

   @media screen and (max-width: 900px) {
      // desk
      width: 40%;
   }

   @media screen and (max-width: 800px) {
      // tablet
      width: 50%;
   }
   @media screen and (max-width: 700px) {
      // tabler
      width: 53%;
   }

   @media screen and (max-width: 600px) {
      // mobile
      width: 90%;

      & .logoIcon {
         width: 35%;
         margin-bottom: 2em;
      }
   }
`;

export const CardAuth = styled.div`
   width: 50%;
   border-radius: 0.5em;
   border-top-right-radius: 0;
   border-top-left-radius: 0;
   overflow: hidden;
   background: ${(props) => props.theme.primary};
   padding: 1em;

   & .logoIcon {
      width: 17%;
      margin: 0 auto;
      display: block !important;
      clear: both;
      margin-bottom: 1.5em;
   }

   @media screen and (max-width: 1050px) {
      // desk
      width: 37%;
   }

   @media screen and (max-width: 900px) {
      // desk
      width: 40%;
   }

   @media screen and (max-width: 800px) {
      // tablet
      width: 50%;
   }
   @media screen and (max-width: 700px) {
      // tabler
      width: 53%;
   }

   @media screen and (max-width: 600px) {
      // mobile
      width: 90%;

      & .logoIcon {
         width: 35%;
         margin-bottom: 2em;
      }
   }
`;

export const BigText = styled.p`
   font-size: 1.8em;
   font-weight: 300;
   color: ${(props) => props.theme.background};
   user-select: none;
   text-align: center;
`;

export const AppText = styled.p`
   font-size: 1em;
   color: ${(props) => props.color || props.theme.background};
   text-align: center;
   user-select: none;
`;

export const AuthLink = styled(Link)`
   font-size: 1.2em;
   font-weight: 400;
   color: ${(props) => props.theme.background};
   text-decoration: none;
   position: relative;
   margin: 0.5em 0;
   user-select: none;

   &.min {
      font-size: 1em;
   }

   &:after {
      content: "";
      position: absolute;
      bottom: -0.3em;
      width: 110%;
      border-bottom: 1px solid ${(props) => props.theme.background};
      left: -5%;
   }
`;

export const AuthButton = styled(Button)`
   border: 1px solid ${(props) => props.theme.background};
   font-size: 0.8em;
   margin: 0 auto;
   margin-top: 1.5em;
   margin-bottom: 2.5em;
   font-weight: 400;
   letter-spacing: 0;
   padding: 0.9em 2.5em;
   transition: all 320ms linear;
   display: block;

   &:hover {
      color: ${(props) => props.theme.primary};
      background: ${(props) => props.theme.background};
      transition: all 320ms linear;
   }
`;

export const AuthButtonLink = styled(Link)`
   border: 1px solid ${(props) => props.theme.background};
   font-size: 0.8em;
   margin: 0 auto;
   margin-top: 1.5em;
   text-align: center;
   font-weight: 400;
   letter-spacing: 0;
   padding: 0.9em 2.5em;
   transition: all 320ms linear;
   display: block;
   border-radius: 0.5em;
   color: ${(props) => props.theme.background};
   text-decoration: none;

   &.last {
      margin-bottom: 2.5em;
   }

   &:hover {
      color: ${(props) => props.theme.primary};
      background: ${(props) => props.theme.background};
      transition: all 320ms linear;
   }
`;

export const ContentCard = styled.div`
   width: 90%;
   margin: 0 auto;

   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;

   & form {
      width: 50%;
      margin-left: auto;
      margin-right: auto;
   }

   @media screen and (max-width: 600px) {
      & form {
         width: 100%;
      }
   }
`;

export const InputLabel = styled.label`
   color: ${(props) => props.placeholderColor || props.theme.primary};
   user-select: none;
   position: absolute;
   top: 0.5em;
   left: 0.5em;
   font-size: 1em;
   transition: all 420ms;
   font-weight: 300;
`;

export const InputStyled = styled.input`
   border: none;
   outline: none;
   width: 100%;
   color: ${(props) => props.color || props.theme.primary};
   padding: 0.5em;
   font-size: 1em;
   background: transparent;
   font-weight: 300;

   &.full,
   &:focus {
      & + ${InputLabel} {
         top: -1.2em;
         transition: all 420ms linear;
         font-size: 0.8em;
         color: ${(props) => props.activePlaceholder || props.theme.background};
      }
   }

   &:-webkit-autofill,
   &:-webkit-autofill:hover,
   &:-webkit-autofill:focus {
      -webkit-text-fill-color: ${(props) => props.color || props.theme.primary};
      -webkit-box-shadow: 0 0 0px 0 ${(props) => props.theme.background} inset;
      transition: background-color 5000s ease-in-out 0s;
   }

   @media screen and (max-width: 600px) {
      // mobile
      font-size: 1.1em;
   }

   /* Chrome, Safari, Edge, Opera */
   &::-webkit-outer-spin-button,
   &::-webkit-inner-spin-button {
      -webkit-appearance: none;
   }

   &[type="number"] {
      -moz-appearance: textfield;
   }
   &::-webkit-calendar-picker-indicator {
      display: none;
   }
`;

export const InputContainer = styled.div`
   width: ${(props) => props.width || "100%"};
   position: relative;
   margin: 0;
   margin-top: 1.5em;
   background: ${(props) => props.background || props.theme.background};
   border-radius: 0.5em;
`;

export const ContentButtons = styled.div`
   &.grid {
      margin-bottom: 2em;
      display: flex;

      & .column {
         width: 50%;
         padding: 0.5em;
      }
   }
   & a {
      width: 170px;
      padding-left: 0;
      padding-right: 0;
   }

   @media screen and (max-width: 600px) {
      &.grid {
         flex-direction: column;

         & .column {
            width: 100%;
            padding: 0 0.5em;
         }
      }
   }
`;

export const ToggleForm = styled.div`
   width: 100%;
   position: relative;
   height: 100%;
   min-height: 250px;

   & .animateForm {
      width: 100%;
      top: 0;
      left: 0;
   }
`;

export const StyledForm = styled.form`
   width: 100%;
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;

   & .min {
      width: 47%;
   }

   & label {
      &.labelTitle {
         width: 100%;
         display: block;
         margin-top: 1em;
         margin-bottom: -0.5em;
      }
   }
`;

export const GenreContent = styled.div`
   width: 100%;
   margin-top: 1em;
   display: flex;

   & ${InputLabel} {
      position: initial;
      color: ${(props) => props.theme.background};
      margin-right: 1em;
   }
`;
