import React, {useState, useEffect} from "react";
import {InputStyled, InputContainer, InputLabel} from "../../styles/AuthStyles";
import propTypes from "prop-types";

function Input(props) {
   let {
      color,
      colorError,
      placeholderColor,
      defaultValue,
      disabled,
      required,
      placeholder,
      type,
      name,
      className,
      inputConfig,
      background,
      activePlaceholder,
   } = props;
   const [value, setValue] = useState("");

   useEffect(() => {
      setValue(defaultValue);
   }, [defaultValue]);

   useEffect(() => {
      if (type === "date" && !defaultValue) {
         setValue("2020-01-01");
      }
   }, [type, defaultValue]);

   return (
      <InputContainer className={className} background={background}>
         <InputStyled
            id={name}
            className={value ? "full" : ""}
            color={color}
            value={value}
            disabled={disabled}
            required={required}
            type={type}
            onChange={(e) => {
               setValue(e.target.value);
            }}
            name={name}
            colorError={colorError}
            {...inputConfig}
            activePlaceholder={activePlaceholder}
         />
         <InputLabel placeholderColor={placeholderColor || color} htmlFor={name}>
            {placeholder}
         </InputLabel>
      </InputContainer>
   );
}

export default Input;

Input.defaultProps = {
   type: "text",
   placeholder: "Placeholder",
   defaultValue: "",
   disabled: false,
   required: false,
   colorError: "crimson",
   inputConfig: {},
};

Input.propTypes = {
   name: propTypes.string.isRequired,
};
