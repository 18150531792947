import React, {useEffect} from "react";
import {ModalStyled, BackModal, ContentChildModal} from "../styles/Modals";

const ModalContainer = ({children, close, cancelable, confirm}) => {
   useEffect(() => {
      document.addEventListener("keydown", escFunction, false);
      return () => {
         document.removeEventListener("keydown", escFunction, false);
      };
   });

   function escFunction(event) {
      if (event.keyCode === 27) {
         close();
      }
   }

   function closeModal() {
      close();
   }

   return (
      <ModalStyled>
         {cancelable && <BackModal onDoubleClick={closeModal} />}
         <ContentChildModal>{children}</ContentChildModal>
      </ModalStyled>
   );
};

ModalContainer.defaultProps = {
   cancelable: true,
   close: () => false,
};

export default ModalContainer;
