/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useContext, useEffect} from "react";
import {infoEventService, check_expirationService, createAssistService} from "../services/LiveServices";
// import {useLocation, useHistory} from "react-router-dom";
import {GlobalContext} from "./GlobalProvider";
// import usePrevious from "../hooks/usePrevious";

export const EventOnlineContext = React.createContext();

export default function EventOnlineProvider({children}) {
   const {renewUserToken, setUserType, userProfileData} = useContext(GlobalContext);
   const [infoElement, setInfo] = useState({isLoading: true, data: []});
   const [userCode, setUserCode] = useState("");
   const [codeIsValid, setCodeIsValid] = useState(false);
   const [createInterval, setCreateInterval] = useState(false);
   const [id, setIdEvent] = useState("");
   /* const location = useLocation();
   const history = useHistory(); */
   /* const previousPathName = usePrevious(location.pathname);

   useEffect(() => {
      if (location.pathname !== previousPathName) {
         getUserType();
         setUserCode("");
         setCodeIsValid(false);
      }
   }, [location]); */

   useEffect(() => {
      let checkTime = "";

      if (createInterval && userCode) {
         let toSend = {id_live_event: id, code: userCode};
         checkTime = setInterval(() => {
            initCountByCode(toSend);
         }, 90000);
      }
      return () => {
         clearInterval(checkTime);
      };
   }, [createInterval, userCode]);

   const getInfoevent = (id) => {
      infoEventService(id)
         .then((res) => res.json())
         .then((resp) => {
            const {status, data} = resp;
            if (status === "error") throw new Error();
            setInfo({isLoading: false, data: {...data}});
         })
         .catch(() => {
            setInfo({isLoading: false, data: {}});
         });
   };

   const initCountByCode = (dataSend) => {
      let token = localStorage.getItem("freeticket_token");
      let toSend = {token, ...dataSend};
      check_expirationService(toSend)
         .then((res) => res.json())
         .then((resp) => {
            const {status, data} = resp;
            if (status === "error") throw new Error();
            if (data >= 15) {
               setUserType("byCode");
               setCodeIsValid(false);
               setCreateInterval(false);
               setUserCode("");
               /* setTimeout(() => {
                  // history.push("?modal=pays", {next: location.pathname});
               }, 500); */
            } else {
               setCodeIsValid(true);
            }
         })
         .catch(() => {
            initCountByCode(dataSend);
         });
   };

   const passAssistanceMember = () => {
      if (!infoElement.active) return false;
      let token = localStorage.getItem("freeticket_token");
      let data = {live_event_id: id, user_id: userProfileData.id, type: "membership", token};
      createAssistService(data)
         .then((res) => res.json())
         .then((resp) => {
            const {status} = resp;
            if (status === "error") throw new Error();
         })
         .catch(() => {
            // passAssistanceMember();
         });
   };

   const passAssistanceCode = () => {
      renewUserToken().then((token) => {
         let dataSend = {live_event_id: id, user_id: userProfileData.id, type: "code", token};
         createAssistService(dataSend)
            .then((res) => res.json())
            .then((resp) => {
               const {status} = resp;
               if (status === "error") throw new Error();
            })
            .catch(() => {
               // passAssistanceCode();
            });
      });
   };

   return (
      <EventOnlineContext.Provider
         value={{
            getInfoevent, // obtener info
            infoElement, // info de evento
            userCode, // código de ingreso cuando un usuario entra
            setUserCode, // actualizar código de ingreso
            initCountByCode, // servicio que verifica el timepo
            codeIsValid, // si el tiempo ya acabo
            setCreateInterval, // interval
            setCodeIsValid,
            passAssistanceMember, // pasar asistencia con membresía
            passAssistanceCode, // pasar asisitencia si ingresan con código
            setIdEvent, // current id event
         }}
      >
         {children}
      </EventOnlineContext.Provider>
   );
}
