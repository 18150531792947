/* eslint-disable react-hooks/exhaustive-deps */
import React, {useRef, useState, useEffect} from "react";
import AnimatedView from "../../../containers/AnimatedView";
import Cover from "../../../components/cover/Cover";
import {useLocation, useHistory} from "react-router-dom";
import axios from 'axios';
import {apiUrl} from "../../../services/ApiUrl";
import {photosUrl} from "../../../services/PhotosUrl";
import usePrevious from "../../../hooks/usePrevious";
import {Container, Grid, Paper, Box, Typography } from '@mui/material';
import {Button} from "../../../styles/ButtonStyles";
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import {useSelector} from "react-redux";

export default function EventInfo() {
   const history = useHistory();
   const eventsRef = useRef(null);
   const viewRef = useRef(null);
   const location = useLocation();
   let prevLocation = usePrevious(location);
   const event_id = location.state.event_id; 
   const userProfileData = useSelector((state) => state.auth);

   // Estado para almacenar los datos recibidos
   const [eventInfo, setEventInfo] = useState([]);
   // Estado para almacenar errores
   const [error, setError] = useState(null);

   useEffect(() => {
      axios.get(apiUrl+'event/event_info/'+ event_id)
      .then(response => {
        let data = response.data.data;
        let hour = data.time.split(':');
        data.time = `${hour[0]}:${hour[1]}`;
        data.date = format(new Date(data.date), 'dd/MM/yyyy', { locale: es });
        setEventInfo(data);
      })
      .catch(err => {
        setError(err);
      });
   }, [location]);

   const makeReservation = () => {

      if(!userProfileData.token){
         window.location.href = "/?modal=login";
         return;
      }

      axios.post(apiUrl+'ticket/create_ticket',{event_id:event_id},
         {
            headers: {
               Authorization: `Bearer ${userProfileData.token}`,
            },
         }
      )
      .then(response => {
         console.log(response.data);
         if(response.data.status === 'success'){
            alert("Ticket reservado con éxito");
            history.push({
               pathname: "/ticket_list",
            });
         }
         if(response.data.status === 'error'){

            if(response.data.message == 'El usuario no tiene una membresía activa'){
               window.location.href = "/?modal=user_membership";

            }
            else{
               alert("Algo salió mal, por favor intenta más tarde");
            }
         }
      })
      .catch(err => {
        setError(err);
        alert("Algo salió mal, por favor intenta más tarde");
      });
   };

   return (
      <>
         <AnimatedView showNav={false} ref={viewRef}>
            <Cover background={null}>
                <Container style={{marginTop:'120px'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Paper style={{ padding: 20, minHeight:'300px', color:'black', cursor:'pointer' }}>
                                <Box 
                                    component="img" 
                                    alt="" 
                                    src={`${photosUrl}${eventInfo.inner_img}`} 
                                    sx={{ width: '100%', height: 'auto' }} 
                                />
                            </Paper>
                        </Grid>

                        <Grid item xs={6} style={{ padding: 20}}>
                            <Typography variant="h4" gutterBottom style={{ padding: 20, textAlign:"center"}}>
                                {eventInfo.title}
                            </Typography>
                            <Typography variant="p" gutterBottom>
                                {eventInfo.description}
                            </Typography>
                            <br/><br/>
                            <Typography variant="p" gutterBottom>
                                <strong>Dirección:</strong> {eventInfo.location}
                            </Typography>                            
                            <br/><br/>
                            <Typography variant="p" gutterBottom>
                                <strong>Fecha:</strong> {eventInfo.date}
                            </Typography>
                            <br/><br/>
                            <Typography variant="p" gutterBottom>
                                <strong>Hora:</strong> {eventInfo.time}
                            </Typography>
                            <Grid container spacing={2} style={{paddingTop:"20px"}}>
                              <Grid item xs={6} style={{textAlign:"center"}}>
                                 <Button onClick={makeReservation} className="onlineButton">
                                    Obtener ticket
                                 </Button>
                              </Grid>  
                              <Grid item xs={6} style={{textAlign:"center"}}>
                                 {
                                    /*
                                    <Button className="onlineButton">
                                       REFERIR
                                    </Button>
                                    */
                                 }
                              </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Cover>
         </AnimatedView>
      </>
   );
}
