import React, {useEffect, useRef} from "react";
import AnimatedView from "../../../containers/AnimatedView";
//import Container from "../../../containers/Container";
// import {EventsContext} from "../../../context/EventsProvider";
//import {LoaderComponentFixed} from "../../../components/loader/LoaderComponent";
import styled from "styled-components";
//import EventComponent from "../../../components/event/EventComponent";
import {useDispatch, useSelector} from "react-redux";
//import {getWeekEventsAction} from "../../../ContextProvider/Actions";

import Cover from "../../../components/cover/Cover";
import EventsListComponent from "../home/EventsList";

export default function EventsList() {
   // const {futureEventList, getFutureEvents} = useContext(EventsContext);
   //const dispatch = useDispatch();
   //const {weekEvents, fetchingWeek} = useSelector((state) => state.events);

   const viewRef = useRef(null);


   useEffect(() => {
      //dispatch(getWeekEventsAction());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <>
         <AnimatedView showNav={false} ref={viewRef}>

            <Cover background={null}>
               <EventsListComponent />
            </Cover>               

         </AnimatedView>
      </>
   );
}

