export function sortByDate(arr, keyDate) {
   arr.sort(function (a, b) {
      var keyA = new Date(a[keyDate]),
         keyB = new Date(b[keyDate]);
      // Compare the 2 dates
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
   });
   return arr;
}
