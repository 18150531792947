import React, { useState, useEffect } from "react";
import axios from 'axios';
import { EventListContainer } from "../../../styles/EventList";
import { apiUrl } from "../../../services/ApiUrl";
import { photosUrl } from "../../../services/PhotosUrl";
import { Container, Grid, Box, Link } from '@mui/material';

export default function EventList() {

  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get(apiUrl + 'home_page/get_home_page')
      .then(response => {
        console.log(response.data.data);
        let data = response.data.data;
        setData(data);
        setLoading(false);
      })
      .catch(err => {
        setData([]);
        setError(err);
        setLoading(false);
      });
  }, []);

  const Overlay = ({ text }) => (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: 'yellow',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '20px',
        zIndex: 1,
      }}
    >
      {text}
    </Box>
  );

  const ImageWithOverlay = ({ src, text }) => (
    <Box sx={{ position: 'relative', width: '100%', height: 'auto' }}>
      <Box
        component="img"
        alt=""
        src={src}
        sx={{ width: '100%', height: 'auto' }}
      />
      {text && text.trim() !== "" && <Overlay text={text} />}
    </Box>
  );

  return (
    <EventListContainer>
      <Container>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <Link href={`${data.header_link}`} rel="noopener">
              <ImageWithOverlay 
                src={`https://freeticketapi.trebolbit.com/${data.header_banner}`} 
                text={data.header_text || ""}
              />
            </Link>
          </Grid>
          <Grid item md={6}>
            <ImageWithOverlay 
              src={`https://freeticketapi.trebolbit.com/${data.left_banner}`} 
              text={data.left_text || ""}
            />
          </Grid>
          <Grid item md={6}>
            <ImageWithOverlay 
              src={`https://freeticketapi.trebolbit.com/${data.right_banner}`} 
              text={data.right_text || ""}
            />
          </Grid>
          <Grid item md={12}>
            <ImageWithOverlay 
              src={`https://freeticketapi.trebolbit.com/${data.bottom_banner}`} 
              text={data.bottom_text || ""}
            />
          </Grid>
        </Grid>
      </Container>
    </EventListContainer>
  );
}
