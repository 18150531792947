import React, {useContext} from "react";
import {AboutSection, OfferList, OfferItem} from "./AboutStyles";
import {ThemeContext} from "styled-components";
import Container from "../../../../containers/Container";
import LiveIcon from "../../../../assets/icon_img/live_icon.png";
import EyeIcon from "../../../../assets/icon_img/eye_icon.png";
import ShieldIcon from "../../../../assets/icon_img/lock_icon.png";
import MedalIcon from "../../../../assets/icon_img/medal_icon.png";

export default function OurOffer() {
   const themeContext = useContext(ThemeContext);

   return (
      <AboutSection background={themeContext.primary}>
         <Container>
            <OfferList>
               {React.Children.toArray(
                  OfferArray.map((item) => {
                     const Icon = item.img;
                     return (
                        <OfferItem>
                           <img src={Icon} className="offerIcon" alt="icon" />
                           {/* <Icon width="3.5em" height="3.5em" className="offerIcon" fill={themeContext.background} /> */}
                           <p>{item.description}</p>
                        </OfferItem>
                     );
                  }),
               )}
            </OfferList>
         </Container>
      </AboutSection>
   );
}

const OfferArray = [
   {
      img: LiveIcon,
      description: "Contamos con más de 16 show totalmente en vivo para que disfrutes con tu membresía",
   },
   {
      img: EyeIcon,
      description: "Disfruta de tus shows favoritos desde la comodidad de tu hogar, en cualquier dispositivo",
   },
   {
      img: ShieldIcon,
      description: "Tus datos siempre estarán protegidos en nuestra plataforma. Tu seguridad es primero",
   },
   {
      img: MedalIcon,
      description: "La mejor calidad en streaming. Solo deberas preocuparte por disfrutar al máximo.",
   },
];
