import styled from "styled-components";
import {Link} from "react-router-dom";

export const Button = styled.button`
   text-align: center;
   outline: none;
   border: none;
   background: ${(props) => props.theme.primary};
   font-size: 1em;
   color: ${(props) => props.theme.background};
   padding: 0.7em 1em;
   font-weight: 300;
   border-radius: 0.5em;
   cursor: pointer;
`;

export const ButtonLink = styled(Link)`
   text-align: center;
   outline: none;
   border: none;
   background: ${(props) => props.theme.primary};
   font-size: 1em;
   color: ${(props) => props.theme.background};
   padding: 0.7em 1em;
   font-weight: 300;
   border-radius: 0.5em;
   text-decoration: none;
   display: block;
`;
