/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";
import ModalContainer from "../../../containers/ModalContainer";
import CardModalAuth from "../../../containers/CardModalAuth";
import {ContentCard, ToggleForm} from "../../../styles/AuthStyles";
import {useLocation, useHistory} from "react-router-dom";
import RegisterForm from "./components/RegisterForm";
import {useSelector} from "react-redux";

export default function RegisterView() {
   const {fetching, id} = useSelector((state) => state.auth);
   const location = useLocation();
   const history = useHistory();

   useEffect(() => {
      if (id) return history.replace(location.pathname);
   }, [id]);

   return (
      <ModalContainer
         close={() => {
            history.replace(location.pathname);
         }}
      >
         <CardModalAuth
            close={() => {
               history.replace(location.pathname);
            }}
            back={() => {
               history.replace(location.state ? location.state.back : "?modal=login");
            }}
            isLoading={fetching}
         >
            <ContentCard>
               <ToggleForm>
                  <RegisterForm />
               </ToggleForm>
            </ContentCard>
         </CardModalAuth>
      </ModalContainer>
   );
}
