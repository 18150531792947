import styled from "styled-components";

export const ModalStyled = styled.section`
   width: 100%;
   height: 100%;
   background-color: rgba(10, 10, 10, 0.7);
   position: fixed;
   top: 0;
   left: 0;
   z-index: 99999;

   & .contentModal {
      position: relative;
      z-index: 999999;
      overflow: hidden;
   }
`;
export const BackModal = styled.div`
   width: 100%;
   height: 100%;
   position: fixed;
   top: 0;
   left: 0;
   // z-index: 9999;
`;
export const ContentChildModal = styled.div`
   // position: relative;
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   overflow-y: scroll;
   // padding: 2em 0;
`;
