/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";
import {useLocation, useHistory} from "react-router-dom";
import ModalContainer from "../../../containers/ModalContainer";
import {AppText, ContentCard, BigText, AuthButton} from "../../../styles/AuthStyles";
import CardModalAuth from "../../../containers/CardModalAuth";
import useQuery from "../../../hooks/useQuery";
import Input from "../../../components/auth/Input";
import {useDispatch, useSelector} from "react-redux";
import {redeemMembershipCodeAction} from "../../../ContextProvider/Actions/AuthActions";

// Selccionar método de pago

export default function PayByCode() {
   const dispatch = useDispatch();
   const {fetchingPay, errorMessage, membership} = useSelector((state) => state.auth);
   const location = useLocation();
   const history = useHistory();
   let backModal = useQuery().get("backModal");

   useEffect(() => {
      if (membership) {
         history.replace(location.pathname);
      }
   }, [membership]);

   const handleSendCode = (e) => {
      e.preventDefault();
      dispatch(redeemMembershipCodeAction(e.target.code.value));
   };

   return (
      <ModalContainer
         close={() => {
            history.replace(location.pathname);
         }}
      >
         <CardModalAuth
            isLoading={fetchingPay}
            close={() => {
               history.replace(location.pathname);
            }}
            back={() => {
               if (backModal) {
                  history.replace(`?modal=${backModal}`);
               }
            }}
         >
            <ContentCard>
               <BigText>Adquiere tu membresía</BigText>

               <AppText>
                  Ingresa un <b style={{color: "inherit"}}>código de regalo de membresía</b> para activarlo.
               </AppText>

               <form onSubmit={handleSendCode}>
                  <Input placeholder="Código" name="code" />
                  <AppText color="crimson" style={{fontSize: "0.8em", marginTop: "0.5em"}}>
                     {errorMessage}
                  </AppText>
                  <AuthButton>Canjear código</AuthButton>
               </form>
            </ContentCard>
         </CardModalAuth>
      </ModalContainer>
   );
}
