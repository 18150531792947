/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {useLocation, useHistory} from "react-router-dom";
import ModalContainer from "../../../containers/ModalContainer";
import {AppText, ContentCard, BigText, AuthButtonLink, ContentButtons} from "../../../styles/AuthStyles";
import CardModalAuth from "../../../containers/CardModalAuth";
import useQuery from "../../../hooks/useQuery";
import {useSelector} from "react-redux";
import { SliderRoot } from "@mui/material";

// Selccionar método de pago

export default function PaysMethodView() {
   const location = useLocation();
   const history = useHistory();
   let backModal = useQuery().get("backModal");

   const userProfileData = useSelector((state) => state.auth);

   useEffect(() => {
      if (!userProfileData.token) {
         history.replace(`profile?modal=login`);
      }
   }, []);

   return (
      <ModalContainer
         close={() => {
            history.replace(location.pathname);
         }}
      >
         <CardModalAuth
            close={() => {
               history.replace(location.pathname);
            }}
            back={() => {
               if (backModal) {
                  history.replace(`?modal=${backModal}`);
               }
            }}
         >
            <ContentCard>
               <ContentButtons className="grid">
                  <div className="column">
                     <BigText>Usuario</BigText>
                     <br/>
                     <AppText>
                        Accede a todos los shows de comedia del mes, sin pagos adicionales 
                        y con la posibilidad de reservar ubicaciones exclusivas. 
                     </AppText>
                     <br/>
                     <AppText>Costo: 10 Dlls</AppText>
                     <AuthButtonLink to="?modal=cards" state={{next: location.state ? location.state.next : null}}>
                        Membresía de usuario
                     </AuthButtonLink>
                     
                  </div>
                  <div className="column">
                     <BigText>Negocio</BigText>
                     <br/>
                     <AppText>
                        Tu empresa tiene derecho a 40 tickets físicos 
                        de eventos específicos durante el mes para 
                        clientes, empleados o proveedores.
                     </AppText>
                     <br/>
                     <AppText>Costo: 50 Dlls</AppText>
                     <AuthButtonLink to="?modal=cards_business" state={{next: location.state ? location.state.next : null}}>
                        Membresía de negocio
                     </AuthButtonLink>
                  </div>                  
               </ContentButtons>
            </ContentCard>
         </CardModalAuth>
      </ModalContainer>
   );
}
