/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import ModalContainer from "../../../containers/ModalContainer";
import CardModalAuth from "../../../containers/CardModalAuth";
import {ContentCard, BigText, AppText, AuthButtonLink} from "../../../styles/AuthStyles";
import {useLocation, useHistory} from "react-router-dom";
import {ButtonLink} from "../../../styles/ButtonStyles";
import {useSelector} from "react-redux";

export default function UserMembership() {
   const {fetching} = useSelector((state) => state.auth);
   const location = useLocation();
   const history = useHistory();

   return (
      <ModalContainer
         close={() => {
            history.replace(location.pathname);
         }}
      >
         <CardModalAuth
            close={() => {
               history.replace(location.pathname);
            }}
            back={() => {
               history.replace(location.state ? location.state.back : "?modal=user_membership");
            }}
            isLoading={fetching}
         >
            <ContentCard>
                <BigText>No cuentas con una membresía para disfrutar este evento</BigText>
                <br/>
                <AuthButtonLink to="/?modal=pays" className="button">
                  Adquiérela aquí
                </AuthButtonLink>
            </ContentCard>
         </CardModalAuth>
      </ModalContainer>
   );
}
