import {apiUrl} from "./ApiUrl";

export const LoginService = (email, password) => {
   const service = `${apiUrl}user/login`;
   let data = {email, password};
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({...data}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};
export const UpdateUserService = (data) => {
   const service = `${apiUrl}user/update`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({...data}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};

export const RegisterService = (data) => {
   const service = `${apiUrl}user/register`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({...data}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};
export const userDataService = (token) => {
   console.log("user data service");
   const service = `${apiUrl}user/profile`;
   return fetch(service, {
      method: "GET",
      headers: {
         "Content-Type": "application/json",
         "Authorization": `Bearer ${token}`,
      },
   });
};
export const recoverService = (data) => {
   const service = `${apiUrl}user/recover_password`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({...data}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};
export const changePasswordService = (data) => {
   const service = `${apiUrl}user/update_password`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({password: data.password}),
      headers: {
         "Content-Type": "application/json",
         "Authorization": `Bearer ${data.token}`
      },
   });
};
export const membershipInfo = (data) => {
   const service = `${apiUrl}membership/info`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({...data}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};
export const getToken = () => {
   let promise = new Promise((resolve, reject) => {
      const newToken = localStorage.getItem("token");
      resolve(newToken);
   });
   return promise;
};
export const renewToken = (token, id) => {
   const service = `${apiUrl}user/renew`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({token}),
      headers: {
         "Content-Type": "application/json",
      },
   })
      .then((res) => res.json())
      .then((resp) => {
         const {status, data} = resp;
         if (status === "error") throw new Error();
         return data.token;
      })
      .catch(() => {
         return null;
      });
};
