import {CHANGE_CODE_ERROR, CHANGE_CODE, NETWORK_ERROR, CHANGE_CODE_SUCCESS, GET_REMAING_TIME_SUCCESS} from "../Types";
import {apiUrl} from "../../services/ApiUrl";
import Axios from "axios";
import moment from "moment";
import {check_expirationService} from "../../services/LiveServices";

const saveStreamingData = (getState) => {
   const {streams} = getState().stream;
   localStorage.setItem("streams", encodeURIComponent(JSON.stringify(streams)));
};

export const changeCodeAction = (code, id_live_event, callBack) => async (dispatch, getState) => {
   dispatch({
      type: CHANGE_CODE,
   });

   const {token} = getState().auth;
   const {streams} = getState().stream;

   try {
      const resp = await Axios.post(`${apiUrl}live_ticket/redeem_code`, {code, id_live_event, token});
      if (resp.data.status === "success") {
         // if (true) {
         // TO DO: SET 15 minutes
         var date = moment().add(0, "seconds").add(15, "minutes");
         const streamEvent = {...streams[id_live_event], freecode: code, expirationTime: date, left: 15, validCode: true};
         dispatch({
            type: CHANGE_CODE_SUCCESS,
            payload: {validatingCode: false, streams: {...streams, [id_live_event]: {...streamEvent}}},
         });
         callBack(); // callback to closse
         passAssistanceAction("code", id_live_event)(dispatch, getState); // assisitance
         saveStreamingData(getState); // update localstorage
      } else {
         dispatch({
            type: CHANGE_CODE_SUCCESS,
            payload: {error: resp.data.message, validatingCode: false},
         });
      }
   } catch (e) {
      dispatch({
         type: CHANGE_CODE_ERROR,
         payload: NETWORK_ERROR,
      });
   }
};

export const checkRemainingTime = (toSend) => async (dispatch, getState) => {
   const {streams} = getState().stream;
   const id_live_event = toSend.id_live_event;

   const currentEvent = streams[id_live_event];
   check_expirationService(toSend)
      .then((res) => res.json())
      .then((resp) => {
         const {status, data} = resp;
         if (status === "error") throw new Error();
         let hasTime = data < 15;
         const streamEvent = {
            ...currentEvent,
            freecode: hasTime ? currentEvent.freecode : "expiredCode",
            left: 15 - data,
            validCode: hasTime,
         };
         dispatch({
            type: GET_REMAING_TIME_SUCCESS,
            payload: {streams: {...streams, [id_live_event]: {...streamEvent}}},
         });
         saveStreamingData(getState);
      })
      .catch(() => {
         const currentMoment = moment();
         const streamEvent = {...streams[id_live_event], freecode: "expiredCode", validCode: false};
         if (currentMoment > moment(currentEvent.expirationTime)) {
            dispatch({
               type: GET_REMAING_TIME_SUCCESS,
               payload: {streams: {...streams, [id_live_event]: {...streamEvent}}},
            });
            saveStreamingData(getState);
         }
      });
};

export const recoverLocalStream = () => (dispatch) => {
   const localStreams = JSON.parse(decodeURIComponent(localStorage.getItem("streams")));
   dispatch({
      type: CHANGE_CODE_SUCCESS,
      payload: {streams: {...localStreams}},
   });
};

export const passAssistanceAction = (type, live_event_id) => (dispatch, getState) => {
   const {token, id} = getState().auth;

   let dataSend = {live_event_id, type, user_id: id, token};

   Axios.post(`${apiUrl}live_attendance/assist`, {...dataSend}).then((resp) => console.log(resp));
   return dispatch;
};
