import React, {useEffect, useState} from "react";
import {ColText, Separator, ContentMember, DataMemberCol, ContentButtons} from "../../../../styles/ProfileStyles";
import {ButtonLink} from "../../../../styles/ButtonStyles";
import {useSelector} from "react-redux";

import axios from 'axios';
import {apiUrl} from "../../../../services/ApiUrl";

export default function MembershipInfo() {
   const {membership, membershipData} = useSelector((state) => state.auth);
   const userProfileData = useSelector((state) => state.auth);
   const [membershipInfo, setMembershipInfo] = useState(false);

   useEffect(() => {

      if (userProfileData.id) {
         getMembershipInfo();
      }

   }, []);

   function getMembershipInfo() {
      axios.get(apiUrl+'membership/get_active_membership', {
         headers: {
           Authorization: `Bearer ${userProfileData.token}`,
         },
      }).then(response => {

         let data = response.data.data;
         console.log("membership data " + JSON.stringify(data));
         if (data.status === false) {
            setMembershipInfo(false);
            return;
         }
         setMembershipInfo(data);

      }).catch(err => {
         setMembershipInfo(false);
      });
   }

   return (
      <>
         <ColText style={{marginTop: "0.5em"}}>
            Tu membresía está <b>{membershipInfo ? "activa" : "desactivada"}</b>
         </ColText>

         <Separator>
            <div className="bar" />
         </Separator>

         {
         /*
         membership ? (
            <ContentMember>
               <DataMemberCol>
                  <ColText className="bold">Activación</ColText>
                  <ColText>{membershipData.date_acquired.substring(0, 10)}</ColText>
               </DataMemberCol>
               <DataMemberCol>
                  <ColText className="bold">Suscripción</ColText>
                  <ColText>Mensual</ColText>
               </DataMemberCol>
               <DataMemberCol>
                  <ColText className="bold">Valor</ColText>
                  <ColText>8 USD</ColText>
               </DataMemberCol>
            </ContentMember>
         ) : (
            <ContentButtons>
               <ButtonLink to="?modal=pays">ADQUIRIR MEMBRESÍA</ButtonLink>
            </ContentButtons>
         )*/
         }
         {
            membershipInfo ? (
               <></>
            ) : (
               <ContentButtons>
                  <ButtonLink to="?modal=pays">ADQUIRIR MEMBRESÍA</ButtonLink>
               </ContentButtons>
            )
         }
      </>
   );
}
