import {CHANGE_CODE, CHANGE_CODE_SUCCESS, CHANGE_CODE_ERROR, GET_REMAING_TIME_SUCCESS, INVALIDATE_CODE} from "../Types";

const initialState = {
   validatingCode: false,
   expirationTime: false,
   assistanceTaken: false,
   streams: {},
   // freeCode,
};

function StreamReducer(state = initialState, action) {
   switch (action.type) {
      case CHANGE_CODE:
         return {...state, validatingCode: true};
      case CHANGE_CODE_SUCCESS:
         return {...state, ...action.payload};
      case CHANGE_CODE_ERROR:
         return {...state, e: action.payload};
      case GET_REMAING_TIME_SUCCESS:
         return {...state, ...action.payload};
      case INVALIDATE_CODE:
         return {...state, ...action.payload};
      default:
         return {...state};
   }
}
export default StreamReducer;
