import React, {useState, useEffect} from "react";
import axios from 'axios';
import {EventListContainer} from "../../../styles/EventList";
import {apiUrl} from "../../../services/ApiUrl";
import {photosUrl} from "../../../services/PhotosUrl";
import { Container, Grid, Paper, Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';


export default function EventList() {

  // Estado para almacenar los datos recibidos
  const [data, setData] = useState([]);
  // Estado para almacenar errores
  const [error, setError] = useState(null);
  // Estado para controlar el "loading"
  const [loading, setLoading] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState('all'); // Nuevo estado para el país seleccionado

  useEffect(() => {
   axios.get(apiUrl+'event/next_events')
     .then(response => {

         let data = response.data.data;
         data.map((event) => {
            event.date = format(new Date(event.date), 'dd/MM/yyyy', { locale: es });
         });
         setData(data);
         setLoading(false);

     })
     .catch(err => {
       setData([]);
       setError(err);
       setLoading(false);
     });
   }, []); 

   const handleCountryChange = (event) => {
      setSelectedCountry(event.target.value);
   };

   const filteredData = selectedCountry === 'all' 
   ? data 
   : data.filter(event => event.country_id == selectedCountry);


   return (
      <>
         <EventListContainer>

            <Container>
               <Typography variant="h4" gutterBottom>Eventos</Typography>

               <select value={selectedCountry} onChange={handleCountryChange} 
                  style={{
                     backgroundColor: "black", 
                     height: "40px", 
                     padding: "10px",
                     border: "solid 2px #E8D907",
                     borderRadius: "10px"
                  }}>
                  <option value="all">Todos los países</option>
                  <option value="1">México</option>
                  <option value="2">Colombia</option>
               </select>

               <br/><br/>

               <Grid container spacing={3}>
                  {
                  filteredData.map(event => (
                     <Grid item xs={6} key={event.id}>
                        <Link 
                           to={{
                              pathname: "/event_info",
                              state: { event_id:event.id  }
                           }}
                        >
                           <Box 
                              component="img" 
                              alt="" 
                              src={`${photosUrl}${event.external_img}`} 
                              sx={{ width: '100%', height: 'auto' }} 
                           />
                        </Link>
                     </Grid>
                  ))
                  }
               </Grid>
            </Container>
         </EventListContainer>
      </>
   );
}
