import styled from "styled-components";
import {Link} from "react-router-dom";

export const HeadStyled = styled.header`
   width: 100%;
   z-index: 10;
   position: relative;
`;
//  NAV bar
export const NavBar = styled.nav`
   width: 100%;
   padding-top: 1em;

   & .navContent {
      width: 100%;
   }
`;

export const ContentNavStyle = styled.div`
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;

   & .deskOption,
   & .mobileOption {
      display: none;
   }

   & .logoNav {
      padding-left: 0;

      & img {
         width: 120px;
      }
   }

   & .onlineButton {
      font-size: 1.1em;
      display: inline-block;
   }

   @media screen and (min-width: 600px) {
      // incio de desk
      & .deskOption {
         display: block;
      }
   }

   @media screen and (max-width: 600px) {
      // incio de desk
      & .mobileOption {
         display: block;
         padding: 0;
         background: transparent;
      }
      & .logoNav {
         padding-left: 0;
         & img {
            width: 70px;
         }
      }
   }
`;

export const ItemNav = styled(Link)`
   text-decoration: none;
   text-align: center;
   padding: 0.5em;
   margin-right: 0.3em;
   font-size: 1.1em;

   &[aria-current="page"] {
      color: ${(props) => props.theme.primary};
   }

   &.doubleMargin {
      margin-left: 1em;
   }
`;

// export const ButtonNav = styled(button``;
