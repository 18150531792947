import React, {useState, useEffect} from "react";
import {CardAuth, HeadCard} from "../styles/AuthStyles";
import {Button} from "../styles/ButtonStyles";
import AnimateImg from "./AnimateImg";
import {useTransition, animated} from "react-spring";
import styled from "styled-components";
import {LoaderComponentFixed} from "../components/loader/LoaderComponent";

const CardModalAuth = ({children, close, isLoading, back, showHeader}) => {
   const [toggle, set] = useState(false);

   useEffect(() => {
      set(true);
      return () => {
         set(false);
      };
   }, []);

   const transitions = useTransition(toggle, (item) => item.key, {
      from: {opacity: 0, transform: "scale(1.1)"},
      enter: {opacity: 1, transform: "scale(1)"},
      leave: {opacity: 0, transform: "scale(0.5)"},
   });
   return transitions.map(
      ({item, key, props}) =>
         item && (
            <ContentChildModal key={key} style={{...props}}>
               {showHeader && (
                  <HeadCard>
                     {back && (
                        <Button className="actionButton backbutton" onClick={back}>
                           &#10094;
                        </Button>
                     )}
                     <Button className="actionButton cancelbutton" onClick={close}>
                        &#10006;
                     </Button>
                  </HeadCard>
               )}

               <CardAuth>
                  <AnimateImg src={require("../assets/brand/freeticket_text_black.png")} alt="freeticket_logo" className="logoIcon" />
                  {children}
                  <LoaderComponentFixed isVisible={isLoading} />
               </CardAuth>
            </ContentChildModal>
         ),
   );
};

export default CardModalAuth;

export const ContentChildModal = styled(animated.div)`
   width: 100%;
   height: 90%;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   margin-top: 3em;
   margin-bottom: 1em;

   & > * {
      position: relative;
      z-index: 10000;
      overflow: hidden;
      overflow-y: scroll;
      max-height: 90vh;
      -ms-overflow-style: none;

      ::-webkit-scrollbar {
         display: none;
      }
   }
`;

CardModalAuth.defaultProps = {
   close: () => false,
   children: <p>Inserta contenido</p>,
   showHeader: true,
};
