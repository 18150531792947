import React, {useEffect, useState} from "react";
import AnimatedView from "../../../containers/AnimatedView";
import Container from "../../../containers/Container";
import {ContentProfileStyled, ProfileTitle, Separator} from "../../../styles/ProfileStyles";
import {LoadComponent} from "../../../components/loader/LoaderComponent";
import Nav from "../../../components/header/Nav";
import MembershipInfo from "./components/MembershipInfo";
import UserInfo from "./components/UserInfo";
import {useSelector, useDispatch} from "react-redux";
import {LOG_OUT} from "../../../ContextProvider/Types";
import {useHistory} from "react-router-dom";
import {ButtonLink} from "../../../styles/ButtonStyles";


export default function ProfileView() {
   const dispath = useDispatch();
   const userProfileData = useSelector((state) => state.auth);
   const [isLoadingData, setLoadingData] = useState(true);
   const [currentUser, setUser] = useState([]);
   const history = useHistory();

   useEffect(() => {
      createArrayData();
   }, [userProfileData]);

   const createArrayData = () => {
      if (!userProfileData.id) return setLoadingData(false);
      let nUserData = [
         {name: "Nombre", value: userProfileData.first_name},
         {name: "Apellidos", value: userProfileData.last_name},
         {name: "Usuario", value: userProfileData.username},
         {name: "Correo eléctronico", value: userProfileData.email, className: "large"},
         {name: "Teléfono", value: userProfileData.phone},
         {name: "Genero", value: userProfileData.genre === "male" ? "Masculino" : "Femenino"},
         {name: "País", value: userProfileData.country_id === 1 ? "México" : "Colombia"},
         {name: "Fecha de nacimiento", value: userProfileData.birthdate, className: "large"},
      ];
      setUser(nUserData);

      setLoadingData(false);
   };

   const doLogout = () => {
      dispath({type: LOG_OUT});
      history.replace("/");
   };

   return (
      <AnimatedView showNav={false}>
         <Container>
            <Nav />
            {userProfileData.id ? (
               <ContentProfileStyled>
                  {isLoadingData ? <LoadComponent isVisible={true} /> : <ProfileTitle>Hola {userProfileData.first_name}!</ProfileTitle>}
                  <MembershipInfo />
                  <UserInfo isLoadingData={isLoadingData} currentUser={currentUser} doLogout={doLogout} />
               </ContentProfileStyled>
            ) : (
               <ContentProfileStyled>
                  <ButtonLink to="?modal=login">INICIAR SESIÓN</ButtonLink>
               </ContentProfileStyled>
            )}
         </Container>
      </AnimatedView>
   );
}
