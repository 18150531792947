import React from "react";
import styled from "styled-components";
import ModalContainer from "../../../containers/ModalContainer";
import CardModalAuth from "../../../containers/CardModalAuth";
import {useHistory, useLocation} from "react-router-dom";

const View = styled.div``;

const InfoTitle = styled.p`
   font-size: 1.5em;
   text-align: center;
   color: ${(props) => props.theme.background};
   margin-bottom: 1em;
`;
const InfoParagraph = styled.p`
   font-size: 1em;
   color: ${(props) => props.theme.background};
`;

export default function PrivacyPolice() {
   const history = useHistory();
   let location = useLocation();

   return (
      <ModalContainer>
         <CardModalAuth
            close={() => {
               history.replace(location.pathname);
            }}
         >
            <View style={{flex: 1}}>
               <InfoTitle>Autorización de tratamiento de información </InfoTitle>
               <InfoParagraph>
                  Por medio de la presente autorizo a FREETICKET S.A.S (“FREETICKET”) a realizar el tratamiento de mis datos personales
                  (Nombre, Dirección, Teléfono, Correo Electrónico, Profesión), y la inclusión de los mismos en nuestras bases de datos, con
                  el propósito de utilizar dicha información únicamente y exclusivamente para cumplir con el objetivo de prestar un servicio
                  de alta calidad, y dar a conocer sus ofertas, campañas y servicios, de forma tal que la finalidad y uso que FREETICKET
                  llevará a cabo de la información suministrada será netamente profesional, estadístico, comercial, administrativo y
                  publicitario.
                  <br />
                  <br />
                  En virtud de la legislación local aplicable, el titular de la información personal que es recopilada en esta aplicación,
                  tiene, pero sin limitar los siguientes derechos: 1. Conocer, actualizar, rectificar, oponerse y suprimir o cancelar los
                  datos suministrados. 2. Conocer los usos que se han hecho de la información suministrada, cuando así lo solicite el
                  titular. 3. Revocar la autorización y/o solicitar la supresión del dato suministrado cuando en el tratamiento realizado no
                  se respeten los principios, derechos y garantías constitucionales y legales a favor del titular. 4. Acceder en forma
                  gratuita a sus datos personales que hayan sido objeto de tratamiento por parte de nosotros.
                  <br />
                  <br />
                  De igual forma FREETICKET garantiza la confidencialidad, seguridad, veracidad, transparencia, acceso y circulación
                  restringida de sus datos, cumpliendo con los lineamientos establecidos en la legislación local aplicable, pudiendo el
                  titular de la información suministrada ejercer cualquiera de los derechos mencionados en la regulación, mediante una
                  petición a la dirección electrónica appfreeticket@gmail.com.
                  <br />
                  <br />
                  Por último le recordamos que tanto sus derechos como los principios que cumpliremos al recolectar, almacenar, usar y
                  realizar cualquier tratamiento sobre sus datos, así como también los mecanismos dispuestos para que ejerza sus derechos
                  como Titular de los datos personales están estipulados en el Política de Tratamiento de Datos Personales de Freeticket, la
                  cual se encuentra publicada en appfreeticket.com.
                  <br />
                  <br />
               </InfoParagraph>
            </View>
         </CardModalAuth>
      </ModalContainer>
   );
}
