import React from "react";
import AppRouter from "./router/AppRouter";
import GlobalStyle from "./styles/GlobalStyles";
import {ThemeProvider} from "styled-components";
import {theme} from "./constants/Theme";

function App() {
   return (
      <ThemeProvider theme={theme}>
         <GlobalStyle />
         <AppRouter />
      </ThemeProvider>
   );
}

export default App;
