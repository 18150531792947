import React, {useEffect, useState} from "react";
import axios from 'axios';
import {apiUrl} from "../../../services/ApiUrl";
import {photosUrl} from "../../../services/PhotosUrl";
import AnimatedView from "../../../containers/AnimatedView";
import Container from "../../../containers/Container";
import {ContentProfileStyled, ProfileTitle, Separator} from "../../../styles/ProfileStyles";
import Nav from "../../../components/header/Nav";
import {useSelector} from "react-redux";
import {ButtonLink} from "../../../styles/ButtonStyles";
import {  Grid, Paper, Box, Button } from '@mui/material';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import QRCode from 'qrcode.react';


export default function TicketListView() {
   const userProfileData = useSelector((state) => state.auth);
   // Estado para almacenar los datos recibidos
   const [userTicketList, setUserTicketList] = useState([]);
   const {loggedIn} = useSelector((state) => state.auth);


   useEffect(() => {
    if (loggedIn){
        axios.get(apiUrl+'ticket/get_user_tickets', {
            headers: {
              Authorization: `Bearer ${userProfileData.token}`,
            },
        })
        .then(response => {
            let data = response.data.data;
            data.map((ticket) => {
                let hour = ticket.event.time.split(':');
                ticket.event.time = `${hour[0]}:${hour[1]}`;
                ticket.event.date = format(new Date(ticket.event.date), 'dd/MM/yyyy', { locale: es });
                return ticket;
            });
            setUserTicketList(data);
        })
        .catch(err => {
          setUserTicketList([]);
        });          
    }else{
        setUserTicketList([]);
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [userProfileData]);

   const downloadTicket = (id) => {
    const capturedTicket = document.getElementById('ticket-'+id);
    
    html2canvas(capturedTicket)
      .then(canvas => {
        canvas.toBlob(blob => {
          saveAs(blob, 'ticket.png');
        });
      });
  };


   return (
      <AnimatedView showNav={false}>
         <Container>
            <Nav />
            {userProfileData.id ? (
              <>
                <ContentProfileStyled>
                    <ProfileTitle>Mis Tickets</ProfileTitle>
                    <Separator />
                    <Grid container spacing={3}>
                    {
                        userTicketList.map(ticket => (
                            <Grid item md={4} key={ticket.id}>
                                <Paper>
                                     <Box 
                                        component="img" 
                                        alt="" 
                                        src={`${photosUrl}${ticket.event.inner_img}`} 
                                        sx={{ width: '100%', height: 'auto' }} 
                                    />
                                    <div style={{padding: "20px", color:"black"}} id={`ticket-${ticket.id}`}>
                                        <p style={{color:"black", textAlign:"center", fontSize:"20px", fontWeight:"bold"}}>{ticket.event.title}</p>
                                        <br/>
                                        <p style={{color:"black"}}>Dirección: {ticket.event.location}</p>
                                        <p style={{color:"black"}}>País: {ticket.event.eventCountry.name}</p>
                                        <br/>
                                        <p style={{color:"black", textAlign:"center"}}>Fecha: {ticket.event.date}</p>
                                        <p style={{color:"black", textAlign:"center"}}>Hora: {ticket.event.time}</p>
                                        <br/>
                                        <p style={{color:"black", textAlign:"center"}}>Código: {ticket.code}</p>
                                        <br/>
                                        <div>
                                            <QRCode value={ticket.code} style={{marginLeft:"auto", marginRight:"auto", display:"block"}} />
                                        </div>
                                    </div>
                                    <ButtonLink onClick={() => {downloadTicket(ticket.id)}}>Descargar Ticket</ButtonLink>
                                </Paper>
                            </Grid>
                        ))
                    }
                    </Grid>
                </ContentProfileStyled>
              </>
            ) : (                
               <ContentProfileStyled>
                  <ButtonLink to="?modal=login">INICIAR SESIÓN</ButtonLink>
               </ContentProfileStyled>
            )}
         </Container>
      </AnimatedView>
   );
}
