/* eslint-disable no-useless-escape */
function readCookie(name) {
   return (
      decodeURIComponent(
         document.cookie.replace(
            new RegExp("(?:(?:^|.*;)\\s*" + name.replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"),
            "$1",
         ),
      ) || null
   );
}

export default readCookie;
