import React from "react";
import Nav from "./Nav";
import {HeadStyled} from "../../styles/HeaderStyles";

export default function Header() {
   return (
      <HeadStyled>
         <Nav />
      </HeadStyled>
   );
}
