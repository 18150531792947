import React from "react";
import {BigText, AppText, AuthButton, InputLabel, GenreContent} from "../../../../styles/AuthStyles";
import Input from "../../../../components/auth/Input";
import Select from "../../../../components/auth/Select";
import Checker from "../../../../components/auth/Checker";
import {useDispatch, useSelector} from "react-redux";
import {registerAction} from "../../../../ContextProvider/Actions/AuthActions";
import {useHistory, useLocation} from "react-router-dom";

export default function RegisterForm() {
   const {error} = useSelector((state) => state.auth);
   const dispatch = useDispatch();
   const history = useHistory();
   const location = useLocation();

   const register = (e) => {
      e.preventDefault();
      let newUserData = {
         first_name: e.target.first_name.value,
         last_name: e.target.last_name.value,
         email: e.target.email.value,
         password: e.target.password.value,
         username: e.target.username.value,
         genre: e.target.genre.value,
         birthdate: e.target.birthdate.value,
         phone: e.target.phone.value,
         country_id: Number(e.target.country.value),
         city_id: Number(e.target.country.value) === 1 ? 1 : 3,
         role_id: 1,
      };
      const call = () => history.replace(location.pathname);
      dispatch(registerAction(newUserData, call));
   };
   return (
      <div style={{width: "100%", textAlign: "center"}}>
         <BigText>Regístrate</BigText>
         <AppText>Llena los siguientes campos y disfruta de los mejores eventos en vivo</AppText>

         <form onSubmit={register}>
            {React.Children.toArray(
               InputList.map((newInput) => {
                  return <Input type={newInput.type} name={newInput.name} placeholder={newInput.placeholder} required={true} />;
               }),
            )}

            <Select
               placeholder={{value: "0", name: "Selecciona tu país"}}
               options={[
                  {name: "Colombia", value: 2},
                  {name: "México", value: 1},
               ]}
               name="country"
            />

            <GenreContent>
               <InputLabel>Género:</InputLabel>
               <Checker type="radio" name="genre" value="male" title="H" />
               <Checker type="radio" name="genre" value="female" title="M" />
            </GenreContent>

            {error && (
               <AppText color="crimson" style={{marginTop: 10}}>
                  {error}
               </AppText>
            )}
            <AuthButton>Registrarme</AuthButton>
         </form>
      </div>
   );
}

const InputList = [
   {
      name: "first_name",
      type: "text",
      placeholder: "Nombre",
   },
   {
      name: "last_name",
      type: "text",
      placeholder: "Apellidos",
   },
   {
      name: "username",
      type: "text",
      placeholder: "Usuario",
   },
   {
      name: "birthdate",
      type: "date",
      placeholder: "Fecha de nacimiento",
   },
   {
      name: "email",
      type: "email",
      placeholder: "Correo electrónico",
   },
   {
      name: "password",
      type: "password",
      placeholder: "Contraseña",
   },
   {
      name: "phone",
      type: "tel",
      placeholder: "Teléfono",
   },
];
