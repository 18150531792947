import React, {useState} from "react";
import ModalContainer from "../../../containers/ModalContainer";
import CardModalAuth from "../../../containers/CardModalAuth";
import {ContentCard, BigText, AppText} from "../../../styles/AuthStyles";
import {useLocation, useHistory} from "react-router-dom";
import CheckoutForm from "./stripe/CheckoutForm";

export default function PayBusinessSub() {

   const location = useLocation();
   const history = useHistory();


   return (
      <ModalContainer
         close={() => {
            history.replace(location.pathname);
         }}
      >
         <CardModalAuth
            close={() => {
               history.replace(location.pathname);
            }}
            back={() => {
               history.replace("?modal=pays");
            }}
         >
            <ContentCard>
               <BigText>Membresía de negocio</BigText>
               <br/>
               <AppText>Llena los datos para completar el pago</AppText>
               <br/>
               <CheckoutForm planType={{plan:"business"}}/>
            </ContentCard>
         </CardModalAuth>
      </ModalContainer>
   );
}