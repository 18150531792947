import React, {useState} from "react";
// import {Link} from "react-router-dom";
import ModalContainer from "../../../containers/ModalContainer";
import CardModalAuth from "../../../containers/CardModalAuth";
import {ContentCard, BigText, AppText, AuthButton} from "../../../styles/AuthStyles";
import Input from "../../../components/auth/Input";
import {useLocation, useHistory} from "react-router-dom";
import {recoverService} from "../../../services/AuthServices";

export default function ForgotPasswordView() {
   const [errorText, setError] = useState("");
   const [isLoading, setLoading] = useState("");
   const [isSent, setSent] = useState(false);
   const location = useLocation();
   const history = useHistory();

   const sendEmail = (e) => {
      e.preventDefault();
      setLoading(true);
      setError("");
      const toSend = {email: e.target.email.value};
      recoverService(toSend)
         .then((res) => res.json())
         .then((resp) => {
            const {status} = resp;
            if (status === "error") throw new Error();
            setLoading(false);
         })
         .then(() => {
            setSent(true);
         })
         .catch(() => {
            setError("Algo salió mal, intenta de nuevo.");
            setLoading(false);
         });
   };

   return (
      <ModalContainer
         close={() => {
            history.replace(location.pathname);
         }}
      >
         <CardModalAuth
            close={() => {
               history.replace(location.pathname);
            }}
            back={() => {
               history.replace(location.state ? location.state.back : "?modal=lobby");
            }}
            isLoading={isLoading}
         >
            <ContentCard>
               <BigText>Recuperar contraseña</BigText>
               {isSent ? (
                  <>
                     <AppText>Hemos enviado un correo electrónico para recuperar tu contraseña</AppText>

                     <AuthButton
                        onClick={() => {
                           setSent(false);
                        }}
                     >
                        REENVIAR EMAIL
                     </AuthButton>
                  </>
               ) : (
                  <>
                     <AppText>Escribe tu correo electrónico para poder recupear tu contraseña</AppText>
                     <form onSubmit={sendEmail}>
                        {React.Children.toArray(
                           InputList.map((newInput) => {
                              return <Input type={newInput.type} name={newInput.name} placeholder={newInput.placeholder} required={true} />;
                           }),
                        )}
                        {errorText && (
                           <AppText color="crimson" style={{marginTop: "0.5em"}}>
                              {errorText}
                           </AppText>
                        )}
                        <AuthButton>ENVIAR CORREO</AuthButton>
                     </form>
                  </>
               )}
            </ContentCard>
         </CardModalAuth>
      </ModalContainer>
   );
}

const InputList = [
   {
      name: "email",
      type: "email",
      placeholder: "Email",
   },
];
