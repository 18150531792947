import React from "react";
import {AboutSection, DownAppSection, ColDown, TextAbout, LogoSection, ExternalLink, LinkCont} from "./AboutStyles";
import AnimateImg from "../../../../containers/AnimateImg";
import Container from "../../../../containers/Container";

import DiscoverCalendar from "./DiscoverCalendar";

export default function DownloadApp() {
   return (
      <AboutSection style={{marginTop: "120px"}}>
         <Container>
            <DownAppSection>
               {/*
               <ColDown className="minCol">
                  <LogoSection className="mobile">
                     <AnimateImg src={require("../../../../assets/brand/freeticket_black.png")} alt="freeticket_logo" />
                  </LogoSection>
                  <AnimateImg src={require("../../../../assets/img/mobile_app.png")} className="imgdown" alt="mobile_app_freeticket" />
               </ColDown>
               */}
               <ColDown className="maxCol">
                  <LogoSection>
                     <AnimateImg src={require("../../../../assets/brand/freeticket_black.png")} alt="freeticket_logo" />
                  </LogoSection>
                  <TextAbout>
                  Freeticket ofrece membresías que permiten acceder a shows de stand-up comedy de forma gratuita. 
                  Por una tarifa de membresía, los usuarios pueden disfrutar de una amplia selección de espectáculos cómicos sin costo adicional.
                  </TextAbout>
                  {/*
                  <TextAbout>
                     Descarga nuestra <b>app</b> FREETICKET te REGALA las mejores experiencias de tu vida! Te obsequiamos tickets para los mejores eventos de tu ciudad, 
                     podrás encontrar, teatro, comedia, deportes, viajes, concierto y más, todo por qué el futuro es gratis!
                  </TextAbout>
                  
                  <LinkCont>
                     <TextAbout>Disponible en:</TextAbout>
                     <div className="iconsL">
                        <ExternalLink href="https://apps.apple.com/us/app/free-ticket/id1460338615?l=es" target="_blanck">
                           <AnimateImg src={require("../../../../assets/banner/app_store_icon.png")} className="imgLink" />
                        </ExternalLink> 
                        <ExternalLink
                           href="https://play.google.com/store/apps/details?id=com.emeraldStudio.FreeTicket&hl=es"
                           target="_blanc"
                        >
                           <AnimateImg src={require("../../../../assets/banner/google_play_icon.png")} className="imgLink" />
                        </ExternalLink>
                     </div>
                  </LinkCont>
                  */}
               </ColDown>
            </DownAppSection>
            <DiscoverCalendar />
         </Container>
      </AboutSection>
   );
}
