import {
   LOGIN,
   LOGIN_SUCCESS,
   LOGIN_ERROR,
   GET_USERDATA,
   GET_USERDATA_SUCCESS,
   NETWORK_ERROR,
   UPDATE_PROFILE_SUCCESS,
   UPDATE_PROFILE_ERROR,
   GET_MEMBERSHIP,
   GET_MEMBERSHIP_SUCCESS,
   GET_MEMBERSHIP_ERROR,
   REGISTER_USER,
   REGISTER_USER_ERROR,
   RENEW_TOKEN,
   PAY_MEMBERSHIP,
   PAY_MEMBERSHIP_SUCCESS,
   PAY_MEMBERSHIP_ERROR,
} from "../Types";
import Axios from "axios";
import {apiUrl} from "../../services/ApiUrl";
import readCookie from "../../helpers/readCookie";
import {push} from "connected-react-router";

const URL = `${apiUrl}user/`;

export const initAuthAction = () => (dispatch, getState) => {
   // get data from cookies
   const token = readCookie("token");
   if (token) {
      // if token exist => do login
      dispatch({
         type: LOGIN_SUCCESS,
         payload: token,
      });
      getUserDataAction()(dispatch, getState);
   }
};

export const renewTokenAction = () => async (dispatch, getState) => {
   // get local token
   const {token, id} = getState().auth;
   const resp = await Axios.post(`${URL}renew`, {token, id});
   let newToken = resp.data.data.token;
   // set and save new TOKEN
   document.cookie = `token=${encodeURIComponent(newToken)}`;
   dispatch({
      type: RENEW_TOKEN,
      payload: {token: newToken},
   });
};

export const doLoginEmailAction = (email, password) => async (dispatch, getState) => {
   // login loader
   dispatch({
      type: LOGIN,
      payload: null,
   });

   try {
      // service login
      const resp = await Axios.post(`${URL}login`, {
         email,
         password,
      });

      let {status, data} = resp.data;
      if (status === "error")
         return dispatch({
            type: LOGIN_ERROR,
            payload: "Datos incorrectos",
         });
      dispatch({
         type: LOGIN_SUCCESS,
         payload: data.token,
      });
      // call user data
      getUserDataAction()(dispatch, getState);
      // set token as coockie
      document.cookie = `token=${encodeURIComponent(data.token)}`;
   } catch (e) {
      dispatch({
         type: LOGIN_ERROR,
         payload: NETWORK_ERROR,
      });
   }
};

export const getUserDataAction = () => async (dispatch, getState) => {
   // loading
   dispatch({
      type: GET_USERDATA,
      payload: null,
   });

   // get local token
   const {token} = getState().auth;
   try {
      //const resp = await Axios.get(`${URL}profile`, {token});
      const resp = await Axios.get(`${URL}profile`, {
         headers: {
            'Authorization': `Bearer ${token}`
         }
      });      
      // clean data to save
      const {password, created, ...userData} = resp.data.data;
      dispatch({
         type: GET_USERDATA_SUCCESS,
         payload: {...userData},
      });
      //renewTokenAction()(dispatch, getState);
      //getMembershipStatus()(dispatch, getState);
   } catch (e) {
      dispatch({
         type: GET_USERDATA_SUCCESS,
         payload: NETWORK_ERROR,
      });
   }
};

export const updateUserDataAction = () => (dispatch, getState) => {
   const {userDataRegister, token} = getState().auth;

   Axios.post(`${URL}update`, {...userDataRegister, token})
      .then(() => {
         dispatch({
            type: UPDATE_PROFILE_SUCCESS,
            payload: {userDataRegister: null},
         });
         dispatch(push("/"));
      })
      .catch(() => {
         dispatch({
            type: UPDATE_PROFILE_ERROR,
            payload: NETWORK_ERROR,
         });
      });
};

// export const getMembershipStatus = (val = false) => async (dispatch, getState) => {
export const getMembershipStatus = () => async (dispatch, getState) => {
   // status of membership
   const {id, token} = getState().auth;
   dispatch({
      type: GET_MEMBERSHIP,
   });

   try {
      const resp = await Axios.post(`${apiUrl}membership/info`, {token, id_user: id});
      // TO DO: CHANGE LINES
      // const member = false;
      const member = !!resp.data?.data?.status || false;
      dispatch({
         type: GET_MEMBERSHIP_SUCCESS,
         payload: {membershipData: resp.data?.data, membership: member},
      });
   } catch (e) {
      return dispatch({
         type: GET_MEMBERSHIP_ERROR,
         payload: NETWORK_ERROR,
      });
   }
};

export const registerAction = (newUser, callBack) => async (dispatch, getState) => {
   dispatch({
      type: REGISTER_USER,
   });
   const {token} = getState().auth;
   try {
      const resp = await Axios.post(`${URL}register`, {...newUser, token});
      const {status, data} = resp.data;
      if (status === "error") {
         if (data === "User already exists") {
            return dispatch({
               type: REGISTER_USER_ERROR,
               payload: "EL USUARIO YA EXISTE, INICIA SESIÓN",
            });
         } else {
            return dispatch({
               type: REGISTER_USER_ERROR,
               payload: "Ocurrio un error, intenta más tarde",
            });
         }
      }
      doLoginEmailAction(newUser.email, newUser.password)(dispatch, getState);
      callBack();
   } catch (e) {
      return dispatch({
         type: REGISTER_USER_ERROR,
         payload: NETWORK_ERROR,
      });
   }
};

export const payMembershipAction = (send) => async (dispatch, getState) => {
   dispatch({
      type: PAY_MEMBERSHIP,
   });

   const service = `${apiUrl}stripe/pay`;
   const {token} = getState().auth;

   try {
      const res = await fetch(service, {
         method: "POST",
         body: JSON.stringify({...send, token}),
         headers: {
            "Content-Type": "application/json",
         },
      });
      const resp = await res.json();
      const {status} = resp;
      if (status === "error") throw new Error();
      dispatch({
         type: PAY_MEMBERSHIP_SUCCESS,
      });
      getMembershipStatus()(dispatch, getState);
   } catch (e) {
      dispatch({
         type: PAY_MEMBERSHIP_ERROR,
         payload: {payError: "Algo salió mal. Revisa tus datos o intenta más tarde."},
      });
   }
};

export const redeemMembershipCodeAction = (code) => (dispatch, getState) => {
   const service = `${apiUrl}membership/redeem_membership_ticket`;
   const {token, id} = getState().auth;

   dispatch({
      type: PAY_MEMBERSHIP,
   }); // loading

   Axios.post(service, {token, code, id_user: id}).then((resp) => {
      const {status, message} = resp.data;
      if (status === "success") {
         dispatch({
            type: PAY_MEMBERSHIP_SUCCESS,
         });
         getMembershipStatus()(dispatch, getState);
      }
      dispatch({
         type: PAY_MEMBERSHIP_ERROR,
         payload: {errorMessage: message},
      });
   });
};
