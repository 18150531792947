import React, {useState} from "react";
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import {AuthButton} from "../../../../styles/AuthStyles";
import {useSelector} from "react-redux";
import {useLocation, useHistory} from "react-router-dom";
import axios from 'axios';
import {apiUrl} from "../../../../services/ApiUrl";

const stripePromise = loadStripe('pk_live_5vnDMIiIiEOYB4s9BgqW2Uwr00s8YJ0NPt');
//const stripePromise = loadStripe('pk_test_tb1jm7Yg2KnfWoJZqK1CpiCK00rSySHjkc');
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#E8D906",
      fontSize: "1em",
      fontWeight: "300",
      backgroundColor: "#000000",
      borderRadius: "8px",
      height: "25px",
      padding: '12px 0',
      "::placeholder": {
        color: "#E8D906"
      }
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  }
};


const CheckoutForm = ({ planType }) => {
  const stripe = useStripe();
  const elements = useElements();

  const userProfileData = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);  
  const location = useLocation();
  const history = useHistory();
  const [processingPayment, setProcessingPayment] = useState(false);  

  console.log('[Plan type]', planType);
  const handleSubmit = async (event) => {
    setProcessingPayment(true);
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js aún no se ha cargado o hay un problema con la inicialización.
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const {error, token} = await stripe.createToken(cardElement);

    if (error) {
      console.log('[error]', error);
      setProcessingPayment(false);
    } else {
      payMembershipAction({
        token: token.id,
        plan: planType.plan
      });
    }
  };

  function payMembershipAction(data) {
    setIsLoading(true);
    setProcessingPayment(true);
    axios.post(apiUrl+'stripe/pay', data,{
       headers: {
         Authorization: `Bearer ${userProfileData.token}`,
       },
    }).then(response => {
       setIsLoading(false);
       setProcessingPayment(false);
       let data_response = response.data;
       if (data_response.status === "error") {
          alert("Error al procesar el pago");
          //setErrorMessage(data_response.message);
          return;
       }
       if(data_response.status === "success"){
          //Re dirigir o re cargar
          alert("Pago realizado con éxito");
          history.push("/profile");
          window.location.reload();
          return;
       }
    }).catch(err => {
       setIsLoading(false);
       setProcessingPayment(false);
       alert("Error al realizar el pago");
    });
 }

  return (
    <form onSubmit={handleSubmit}>
      <CardElement options={CARD_ELEMENT_OPTIONS} />
      <AuthButton type="submit" disabled={processingPayment}>
        Pagar
      </AuthButton>
    </form>
  );
};

function App({planType}) {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm planType={planType} />
    </Elements>
  );
}

export default App;
