import {apiUrl} from "./ApiUrl";

export const listLiveEventsService = () => {
   const service = `${apiUrl}live_event/list`;
   return fetch(service);
};
export const listFutureService = () => {
   const service = `${apiUrl}live_event/list_future`;
   return fetch(service);
};
export const listTodayService = () => {
   // http://freeticket.trebolbit.com/api/live_event/list_today
   const service = `${apiUrl}live_event/list_today`;
   return fetch(service);
};
export const listAllEventsService = () => {
   // http://freeticket.trebolbit.com/api/live_event/list_today
   const service = `${apiUrl}live_event/list`;
   return fetch(service);
};
export const infoEventService = (live_event_id) => {
   const service = `${apiUrl}live_event/info`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({live_event_id}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};
export const redeem_codeService = (data) => {
   const service = `${apiUrl}live_ticket/redeem_code`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({...data}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};
export const check_expirationService = (data) => {
   const service = `${apiUrl}live_ticket/check_expiration`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({...data}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};
export const createAssistService = (data) => {
   const service = `${apiUrl}live_attendance/assist`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({...data}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};
