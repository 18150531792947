import React, {useState} from "react";
import {LazyLoadImage} from "react-lazy-load-image-component";

export default function AnimateImg(props) {
   const [isLoad, setLoad] = useState(false);

   const loadStyle = {opacity: 1, transition: "transform 420ms ease, opacity 530ms linear"};
   const loadingStyle = {opacity: 0, transition: "transform 420ms ease, opacity 530ms linear"};

   return (
      <LazyLoadImage
         afterLoad={() => {
            setLoad(true);
         }}
         src={props.src}
         alt={props.alt}
         effect="blur"
         wrapperClassName={`${props.className} animate-img`}
         style={isLoad ? loadStyle : loadingStyle}
      />
   );
}
