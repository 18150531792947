/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef} from "react";
import AnimatedView from "../../../containers/AnimatedView";
import Cover from "../../../components/cover/Cover";
import {useLocation} from "react-router-dom";
import usePrevious from "../../../hooks/usePrevious";
//import EventsList from "./EventsList";
import HomeGrid from "./HomeGrid";

export default function HomeView() {
   const eventsRef = useRef(null);
   const viewRef = useRef(null);
   const location = useLocation();
   let prevLocation = usePrevious(location);

   useEffect(() => {
      moveTo();
   }, [location]);

   const moveTo = () => {
      let goToHash = location.hash.replace("#", "");
      if (goToHash) {
         if (goToHash === "events") {
            setTimeout(() => {
               window.scrollTo({top: eventsRef.current.offsetTop, behavior: "smooth"});
            }, 300);
         }
      } else {
         if (prevLocation) {
            if (prevLocation.pathname === location.pathname) return false;
         }
         window.scrollTo({top: 0});
      }
   };

   return (
      <>
         <AnimatedView showNav={false} ref={viewRef}>

            <Cover background={null}>
               {
                  /*
                     <EventsList />
                  */
               }
               <HomeGrid />
            </Cover>

         </AnimatedView>
      </>
   );
}
