import React, {useEffect} from "react";
import {useLocation, useHistory} from "react-router-dom";
import ModalContainer from "../../../containers/ModalContainer";
import {AppText, AuthLink, ContentCard, BigText, AuthButton} from "../../../styles/AuthStyles";
import CardModalAuth from "../../../containers/CardModalAuth";
import Input from "../../../components/auth/Input";
import {useSelector, useDispatch} from "react-redux";
import {doLoginEmailAction} from "../../../ContextProvider/Actions";

function LoginView() {
   const {fetching, error, loggedIn, id} = useSelector((state) => state.auth);
   const dispatch = useDispatch();

   const location = useLocation();
   const history = useHistory();

   useEffect(() => {
      loggedIn && id && history.replace(location.pathname);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [loggedIn, id]);

   /* useEffect(() => {
      if (userProfileData.id) {
         redirectTo();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [userType]); */

   useEffect(() => {
      // buscar datos previos
      if (location?.state?.toLogin) {
         let email = location.state.toLogin.email;
         let password = location.state.toLogin.password;
         dispatch(doLoginEmailAction(email, password));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [location]);

   const doLogin = (e) => {
      e.preventDefault();
      let email = e.target.email.value;
      let password = e.target.password.value;
      dispatch(doLoginEmailAction(email, password));
   };

   /* const redirectTo = () => {
      if (location.pathname.indexOf("stream") > -1) {
         if (!!infoElement.data?.active) {
            if (userType !== "full") {
               return history.replace("?modal=lobby", {next: location.state?.next ?? ""});
            }
            if (location.state?.next) {
               return history.replace(location.state?.next);
            }
         }
         return history.replace(location.pathname);
      } else {
         history.replace(location.pathname);
      }
   }; */

   return (
      <ModalContainer
         close={() => {
            history.push(location.pathname);
         }}
      >
         <CardModalAuth
            close={() => {
               history.replace(location.pathname, {replace: true});
            }}
            isLoading={fetching}
            // isLoading={isLoading}
         >
            <ContentCard>
               <BigText>Inicia tu sesión</BigText>

               <AppText>Inicia tu sesión para disfrutar los beneficios de Freeticket</AppText>

               <form onSubmit={doLogin}>
                  <Input placeholder="Correo electrónico" type="email" name="email" required />
                  <Input placeholder="Contraseña" type="password" name="password" required />
                  <AppText color="crimson" style={{marginTop: "1em"}}>
                     {error}
                  </AppText>
                  <AuthButton>INICIAR SESIÓN</AuthButton>
               </form>
               <div style={{marginTop: "-1em"}} />
               <AuthLink className="min" to="?modal=forgot" state={{back: location?.search, next: location?.state?.next}}>
                  Olvide mi contraseña
               </AuthLink>
               <div style={{marginBottom: "1em"}} />
               <AppText>Aún no eres miembro?</AppText>
               <AuthLink to="?modal=register" state={{back: location?.search, next: location?.state?.next}}>
                  Regístrate ahora
               </AuthLink>
            </ContentCard>
         </CardModalAuth>
      </ModalContainer>
   );
}

export default LoginView;
