import React from "react";
// import {Link} from "react-router-dom";
import ModalContainer from "../../../containers/ModalContainer";
import CardModalAuth from "../../../containers/CardModalAuth";
import {ContentCard, BigText, AppText, AuthButton} from "../../../styles/AuthStyles";
import Input from "../../../components/auth/Input";
import {useLocation, useHistory, useParams} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {changeCodeAction} from "../../../ContextProvider/Actions/StreamActions";

export default function InsertCodeView() {
   const dispatch = useDispatch();
   const {validatingCode, error} = useSelector((state) => state.stream);
   const location = useLocation();
   const history = useHistory();
   const {id} = useParams();

   const sendCode = (e) => {
      e.preventDefault();
      let code = e.target.code.value;
      const callBack = () => history.replace(location.pathname);
      dispatch(changeCodeAction(code, id, callBack));
   };

   return (
      <ModalContainer
         close={() => {
            history.replace(location.pathname);
         }}
      >
         <CardModalAuth
            close={() => {
               history.replace(location.pathname);
            }}
            back={() => {
               history.replace(location.state ? location.state.back : "?modal=lobby");
            }}
            isLoading={validatingCode}
         >
            <ContentCard>
               <BigText>Ingresa tu código</BigText>
               <AppText>Ingresa tu código para disfrutar 15 minutos de tu transmsión en vivo favorita.</AppText>

               <form onSubmit={sendCode}>
                  {React.Children.toArray(
                     InputList.map((newInput) => {
                        return <Input type={newInput.type} name={newInput.name} placeholder={newInput.placeholder} required={true} />;
                     }),
                  )}

                  <AppText color="crimson" style={{marginTop: "0.5em"}}>
                     {error}
                  </AppText>

                  <AuthButton>INGRESAR AHORA</AuthButton>
               </form>
            </ContentCard>
         </CardModalAuth>
      </ModalContainer>
   );
}

const InputList = [
   {
      name: "code",
      type: "text",
      placeholder: "Código de cortesía",
   },
];
